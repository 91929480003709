<template>
  <div>
    <portal :to="portalTargetName" v-bind:key="index" v-for="(media, index) in tweet.mediaFile">
      <div
        class="app-create-image-desc relative flex w-full items-center justify-between rounded-lg bg-gray-10"
        :class="{
          'px-2 py-0.5': containerSize === 'sm',
          'p-2': containerSize === 'md',
        }"
      >
        <div
          @click="triggerFileUpload(tweet.mediaFile.indexOf(media))"
          class="upload-container flex-shrink-0 mr-4"
        >
          <img v-if="media.type === 'image' || media.type === 'gif'" :src="media.url" />
          <video
            v-if="media.type === 'video' && media._type !== 'youtube-short'"
            disabledRemotePlayback
          >
            <source :src="media.url" />
          </video>
          <video
            v-if="
              media.type === 'video' && media._type === 'youtube-short' && media.status === 'done'
            "
            disabledRemotePlayback
          >
            <source :src="media.url" />
          </video>
          <img
            v-else-if="media._type === 'youtube-short' && media.status === 'rendering'"
            :src="media.thumbnail"
          />
        </div>
        <base-input
          v-bind:key="index"
          v-if="!['instagram'].includes(postType)"
          ref="textarea-container"
          alternative
        >
          <input
            data-cy="image-description-input"
            class="form-control simple"
            :class="{
              'container-input-sm': containerSize === 'sm',
              'container-input-md': containerSize === 'md',
            }"
            rows="3"
            v-model="altTextArray[index]"
            @input="updateAltText(tweet, index)"
            :placeholder="getDescriptionPlaceholder(media)"
            type="text"
          />
        </base-input>
        <div
          v-else-if="showLinkedInBlock && imageCountInThread > 0"
          class="flex text-muted text-xs space-x-2 items-center w-full"
        >
          <inline-svg src="/img/icons/info.svg"></inline-svg>
          <span><em>Video will not be shared to LinkedIn when the thread has images.</em></span>
        </div>
        <span v-if="uploadingMedia.includes(tweet.mediaFile.indexOf(media))">
          <inline-svg src="/img/icons/loading.svg" class="animate-spin" />
        </span>
        <div
          v-else-if="!hideInput"
          class="cursor-pointer opacity-25"
          @click="clearMediaSelectionAtIndex(tweet, tweet.mediaFile.indexOf(media))"
          data-cy="delete-image"
          id="remove-media"
        >
          <i class="fas fa-times-circle"></i>
        </div>
      </div>
    </portal>

    <div v-if="!hideInput" class="upload-icon col-3 px-0">
      <div
        @click="triggerFileUpload(tweet.mediaFile.length)"
        class="btn"
        :class="{
          disabled: !canAddMedia || disabled,
        }"
      >
        <div class="w-5 h-5 cursor-pointer">
          <gallery-icon />
        </div>
      </div>
      <div
        class="image-preview text-center add-image-icon d-flex align-items-center justify-content-center flex-wrap"
      ></div>
    </div>
    <input
      data-cy="upload-input"
      @change="filesChange(tweet, $event.target.files, $event)"
      ref="uploadBtn"
      type="file"
      class="hidden-file-input"
      multiple
    />
  </div>
</template>

<script>
  import UploadContainerMixin from '../views/Mixins/UploadContainerMixin';
  import galleryIcon from './Icons/galleryIcon.vue';

  export default {
    components: { galleryIcon },
    name: 'upload-container',
    mounted() {
      this.altTextArray = this.tweet.media.map((e) => e.altText);
    },
    watch: {
      pastedFiles(val) {
        if (val.length === 0) return;
        this.mediaStartIndex = this.tweet.mediaFile.length;
        this.filesChange(this.tweet, val);
      },
      altTextArray(val) {
        if (!val) return;
        val.forEach((element, index) => {
          if (this.tweet.media[index]) this.tweet.media[index].altText = element;
        });
      },
      tweet(val) {
        this.altTextArray = val.media.map((e) => e.altText);
      },
    },
    props: {
      isShareOnThreadsEnabled: {
        type: Boolean,
        default: false,
      },
      youtubeShortRef: {
        type: Object,
        default: null,
      },
      tweet: Object,
      containerSize: {
        type: String,
        default: 'md',
        validator: (value) => ['sm', 'md'].includes(value),
      },
      portalTargetName: {
        type: String,
        default: Date.now().toString(),
      },
      editThreadModal: Boolean,
      clearMediaSelectionAtIndexParent: Function,
      canAddMedia: Boolean,
      canAddMoreVideos: Boolean,
      imageCountInThread: {
        type: Number,
        default: 0,
      },
      pastedFiles: Array,
      postType: {
        type: String,
        default: null,
      },
      showLinkedInBlock: {
        type: Boolean,
        default: false,
      },
      showFacebookBlock: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      hideInput: {
        type: Boolean,
        default: false,
      },
      uploadingMedia: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        altTextArray: [],
      };
    },
    methods: {
      getDescriptionPlaceholder(media) {
        let placeholder = `Add ${media.type}`;

        if (this.postType === 'linkedin') {
          placeholder += ' title';
        } else {
          placeholder += ' description';
        }
        return placeholder;
      },
      clearMediaSelectionAtIndex(tweet, i) {
        this.altTextArray.splice(i, 1);
        this.clearMediaSelectionAtIndexParent(tweet, i);
        this.$forceUpdate();
      },
      updateAltText(tweet, i) {
        tweet.mediaFile[i].altText = this.altTextArray[i];
      },
    },
    mixins: [UploadContainerMixin],
  };
</script>

<style lang="scss" scoped>
  body {
    &.new-ui {
      .container-input-sm {
        @apply text-sm;
      }
      .container-input-md {
        @apply text-lg;
      }
    }
  }
</style>
