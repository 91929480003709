<template>
  <div v-if="media.length > 0">
    <div class="flex items-center space-x-4" v-if="media.length === 1">
      <div
        class="video-container"
        v-if="media[0] && media[0].type === 'reel'"
        :class="!marginTop && 'no-margin-top'"
      >
        <video @click="expand(media[0].url)" disabledRemotePlayback>
          <source :src="media[0].url" />
        </video>
      </div>
      <div
        class="col-12 image-container"
        :class="marginTop && 'no-margin-top'"
        v-else-if="media[0].type === 'reel' && media[0].thumbnail"
      >
        <img
          @click="expand(media[0].name)"
          ref="image"
          :src="buildStorageMediaURL(media[0].thumbnail)"
        />
      </div>
      <div
        class="video-container"
        v-if="media[0] && media[0]._type === 'youtube-short' && media[0].url"
        :class="!marginTop && 'no-margin-top'"
      >
        <video @click="expand(media[0].url)" disabledRemotePlayback>
          <source :src="media[0].url" />
        </video>
      </div>

      <div
        class="col-12 image-container"
        :class="marginTop && 'no-margin-top'"
        v-else-if="media[0]._type === 'youtube-short' && media[0].thumbnail"
      >
        <img
          @click="expand(media[0].thumbnail)"
          ref="image"
          :src="buildStorageMediaURL(media[0].thumbnail)"
        />
      </div>
      <div
        class="video-container"
        v-else-if="media[0] && media[0].type.includes('video')"
        :class="!marginTop && 'no-margin-top'"
      >
        <video
          @click="expand(media[0].name)"
          disabledRemotePlayback
          v-if="media[0].type.includes('video')"
        >
          <source :src="buildStorageMediaURL(media[0].name)" />
        </video>
      </div>
      <div
        class="col-12 rounded border-2 p-2"
        :class="marginTop && 'no-margin-top'"
        v-else-if="media[0] && media[0].type.includes('pdf')"
      >
        PDF File
      </div>
      <div class="col-12 image-container" :class="marginTop && 'no-margin-top'" v-else>
        <img
          @click="expand(media[0].name)"
          ref="image"
          :src="buildStorageMediaURL(media[0].name)"
        />
      </div>
    </div>
    <div class="flex items-center space-x-4" v-else-if="media.length > 1">
      <div v-for="m in media" :key="m.name" class="image-container">
        <img
          @click="expand(m.name)"
          ref="image"
          :src="m.thumbnail ? buildStorageMediaURL(m.thumbnail) : buildStorageMediaURL(m.name)"
        />
      </div>
    </div>
    <base-modal v-if="expanded" @close="close" title="Media Preview">
      <div class="flex min-h-32 w-591 items-center justify-center">
        <video
          ref="selectedVideo"
          v-if="
            media[0] &&
            (media[0].type === 'reel' || (media[0]._type === 'youtube-short' && media[0].url))
          "
          :style="`${isAspectRatioInverted ? 'height: 600px' : ''}`"
          controls
          style="height: 600px"
        >
          <source :src="media[0].url" />
        </video>
        <img
          v-else-if="media[0] && media[0]._type === 'youtube-short' && media[0].thumbnail"
          class="mx-auto rounded-md"
          :src="buildStorageMediaURL(selectedMedia)"
          alt="youtube short thumbnail"
        />
        <video ref="selectedVideo" v-else-if="media[0] && media[0].type.includes('video')" controls>
          <source :src="buildStorageMediaURL(media[0].name)" />
        </video>
        <img
          v-else
          class="mx-auto rounded-md"
          :src="buildStorageMediaURL(selectedMedia)"
          alt="tweet media"
        />
      </div>
    </base-modal>
  </div>
</template>

<script>
  const config = require('@/config');

  export default {
    name: 'queue-thumbnail',
    props: {
      marginTop: Boolean,
      media: Array,
    },
    data() {
      return {
        buildStorageMediaURL: config.buildStorageMediaURL,
        expanded: false,
        selectedMedia: '',
      };
    },
    computed: {
      isAspectRatioInverted() {
        if (this.$refs.selectedVideo) {
          return this.$refs.selectedVideo.videoWidth < this.$refs.selectedVideo.videoHeight;
        }
        return false;
      },
    },
    methods: {
      expand(media) {
        this.expanded = true;
        this.selectedMedia = media;
      },
      close() {
        if (this.$refs.selectedVideo) {
          this.$refs.selectedVideo.pause();
        }
        this.expanded = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .no-margin-top {
    @apply mt-0;
  }
</style>
