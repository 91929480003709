<template>
  <div
    data-cy="composer-linkedin"
    class="overscroll-behavior-contain mb-13 lg:mb-0"
    :class="{
      'h-full': postType === 'carousel',
    }"
  >
    <div class="flex flex-col gap-y-6">
      <new-alert v-if="!isUserConnectedToLinkedIn && postType !== 'carousel'">
        You can schedule a LinkedIn post but it won't be published unless you
        <router-link class="text-main-color-100 underline" to="/settings#linkedin"
          >connect your LinkedIn account to Hypefury.</router-link
        >
      </new-alert>

      <new-alert
        v-if="
          isUserConnectedToLinkedIn &&
          linkedInTokenInfo &&
          linkedInTokenInfo.isExpired &&
          postType !== 'carousel'
        "
        hasIcon
      >
        This won't be posted to LinkedIn unless you
        <router-link to="/settings" class="text-main-color-100 underline">
          re-authenticate with your LinkedIn account
        </router-link>
      </new-alert>

      <new-alert
        v-if="
          isUserConnectedToLinkedIn &&
          linkedInTokenInfo &&
          !linkedInTokenInfo.isExpired &&
          linkedInTokenInfo.expiresInDays <= 7 &&
          postType !== 'carousel'
        "
        hasIcon
      >
        Your LinkedIn credentials expire in {{ linkedInTokenInfo.expiresInDays }} days. To prevent
        any interruptions in your posting to LinkedIn
        <router-link to="/settings" class="text-main-color-100 underline"
          >refresh the credentials for your LinkedIn account</router-link
        >
      </new-alert>

      <div class="flex items-center justify-between">
        <new-drop-down
          placement="left"
          buttonType="custom"
          class="min-w-0"
          buttonClasses="items-center max-w-full gap-x-3"
          :disabled="!(Array.isArray(linkedInPages) && linkedInPages.length > 1)"
        >
          <template #button="{ isOpen }">
            <img
              :src="userLinkedInData.profilePictureURL || userProfile.photoURL"
              :alt="
                userLinkedInData.profilePictureURL
                  ? userLinkedInData.profilePictureURL.name
                  : userProfile.name
              "
              class="avatar-img"
            />
            <h3 class="avatar-name text-ds-text-primary">{{ userLinkedInData.name }}</h3>

            <inline-svg
              v-if="Array.isArray(linkedInPages) && linkedInPages.length > 1"
              :class="['text-main-color-30', { 'rotate-180 transform': isOpen }]"
              src="/img/icons/chevron-down.svg"
            />
          </template>
          <template>
            <div v-if="Array.isArray(linkedInPages) && linkedInPages.length > 1">
              <div v-for="page of linkedInPages" :key="page.id">
                <div>
                  <input
                    class="hidden"
                    type="checkbox"
                    :id="page.id"
                    :value="page.id"
                    v-model="selectedLinkedInIds"
                    @change="handleLinkedInCheckboxChange"
                  />
                  <label class="text-gray-700 text-sm font-medium" :for="page.id">{{
                    page.name
                  }}</label>
                </div>
              </div>
            </div>
          </template>
        </new-drop-down>
        <div v-if="!isPostOfTypeLinkedIn" class="relative flex items-center space-x-2">
          <label
            data-cy="composer-linkedin-type"
            class="new-custom-option dark-mode-custom-option"
            v-for="(type) in postsTypes"
            :key="type"
            :for="type"
          >
            <input type="radio" name="postType" :id="type" :value="type" v-model="postType" />
            <span class="text-base">{{ capitalize(type) }}</span>
          </label>
        </div>
      </div>
    </div>
    <base-input v-show="postType === 'text' || isPostOfTypeLinkedIn" alternative>
      <textarea
        dir="auto"
        class="form-control simple min-h-16"
        ref="linkedInText"
        rows="3"
        v-model="linkedInText"
        @input="didUserEditLinkedInText = true"
        @focus="onFocus"
        @blur="$emit('blurred')"
        @keyup="resize"
        placeholder="What do you want to share?"
        @keydown.ctrl.enter="$emit('pressed-ctrl-enter')"
        @keydown.meta.enter="$emit('pressed-ctrl-enter')"
        maxlength="3000"
        id="linkedInText"
        data-cy="composer-linkedin-input"
      ></textarea>

      <portal-target
        name="linkedin_post_media"
        multiple
        class="thread-preview focus--hide space-y-2 p-2 pb-0"
      >
      </portal-target>

      <portal-target name="tweetshot-preview"></portal-target>
    </base-input>
    <div v-show="!isPostOfTypeLinkedIn && postType === 'carousel'">
      <VueSlickCarousel
        :key="isThreadFinisherEnabled ? '0' : '1'"
        ref="carousel"
        :infinite="false"
        :arrows="false"
        :dots="true"
      >
        <div ref="firstSlide">
          <div
            class="border relative mx-auto mt-2 flex w-full items-center justify-center rounded-lg border-gray-90 px-8 py-13 lg:max-w-md"
            style="min-height: 24rem"
          >
            <div
              v-if="isLoadingTweetshot || !shouldDisplayTweetshotPreview"
              class="dark-mode-bg absolute flex h-full w-full items-center justify-center rounded-lg bg-gray-60"
            >
              <div
                v-if="!shouldDisplayTweetshotPreview"
                class="flex h-full w-full items-center justify-center"
              >
                Input tweets to see a preview here
              </div>
              <div v-else class="loader" style="width: 24rem; height: 24rem">
                <span class="loading loading-lg">
                  <inline-svg src="/img/icons/loading.svg" />
                </span>
              </div>
            </div>
            <div
              v-else
              class="dark-mode-bg absolute top-0 flex h-full w-full items-center justify-center rounded-lg bg-gray-60 px-8 text-center text-lg opacity-0 duration-200 ease-in hover:opacity-75"
            >
              This is just a preview.<br />
              If you have a thread, all of its tweets will be posted as part of the carousel.
            </div>
            <img
              :src="tweetshotUrl"
              class="tweet-shot-preview rounded-lg"
              alt="tweetShot preview"
            />
          </div>
        </div>
        <div v-if="isThreadFinisherEnabled">
          <div
            class="border relative mx-auto mt-2 flex w-full flex-col items-start justify-center gap-y-4 rounded-lg border-gray-90 px-8 py-13 lg:max-w-md"
            :style="`height: ${slideHeight}px`"
          >
            <div class="flex items-center gap-4">
              <img
                :src="userLinkedInData.profilePictureURL || userProfile.photoURL"
                :alt="
                  userLinkedInData.profilePictureURL
                    ? userLinkedInData.profilePictureURL.name
                    : userProfile.name
                "
                class="avatar-img"
              />
              <span class="avatar-name">{{ userProfile.name }}</span>
            </div>
            <base-input inputGroupClasses="textarea-container" alternative>
              <textarea
                dir="auto"
                class="form-control simple"
                ref="threadFinisherTextarea"
                rows="3"
                placeholder="Text for custom last carousel slide"
                v-model="threadFinisherText"
                maxlength="280"
                data-cy="composer-linkedin-finisher-input"
                @keydown.ctrl.enter="$emit('pressed-ctrl-enter')"
                @keydown.meta.enter="$emit('pressed-ctrl-enter')"
              ></textarea>
              <div class="thread-actions item-center flex p-1">
                <div class="ml-auto flex items-center space-x-1">
                  <div :class="threadFinisherCharactersLeftClass" class="character-count">
                    {{ threadFinisherCharactersLeftCounter }}
                  </div>
                </div>
              </div>
            </base-input>
          </div>
        </div>
      </VueSlickCarousel>
      <div class="ml-12 flex-1">
        <p class="my-5 text-base">
          Your LinkedIn carousel of tweetshots will be posted automatically.
        </p>
        <base-input alternative>
          <textarea
            dir="auto"
            class="form-control simple"
            rows="3"
            v-model="linkedInText"
            @input="didUserEditLinkedInText = true"
            @focus="onFocus"
            @blur="$emit('blurred')"
            @keyup="resize"
            placeholder="Caption for the carousel of tweetshots (optional)"
            @keydown.ctrl.enter="$emit('pressed-ctrl-enter')"
            @keydown.meta.enter="$emit('pressed-ctrl-enter')"
            maxlength="3000"
            id="linkedInText"
            data-cy="composer-linkedin-caption"
          ></textarea>
        </base-input>
      </div>
    </div>
    <portal-target
      v-if="isDesktop"
      :name="`linkedin-actions-${tweet.guid}`"
      multiple
      class="mt-2 flex items-center justify-end gap-x-3 px-2.5 lg:gap-x-6"
    />
    <portal :to="`linkedin-actions-${tweet.guid}`">
      <template v-if="isPostOfTypeLinkedIn">
        <tooltip data-cy="compose-upload-media" :content="uploadContainerTooltipContent">
          <upload-container
            class="my-4"
            :tweet="tweet"
            portalTargetName="linkedin_post_media"
            postType="linkedin"
            :canAddMedia="canAddMedia"
            :canAddMoreVideos="canAddMoreVideos"
            :clearMediaSelectionAtIndexParent="clearMediaSelectionAtIndex"
            :uploadingMedia="uploadingMedia"
          />
        </tooltip>
      </template>
      <template v-else-if="postType === 'text'">
        <tooltip data-cy="compose-upload-media" :content="uploadContainerTooltipContent">
          <upload-container
            v-for="tweetItem in tweets"
            :key="tweetItem.count"
            class="my-4"
            :tweet="tweetItem"
            portalTargetName="linkedin_post_media"
            postType="linkedin"
            :canAddMedia="canAddMedia"
            :canAddMoreVideos="canAddMoreVideos"
            :clearMediaSelectionAtIndexParent="clearMediaSelectionAtIndex"
            :hideInput="true"
            :uploadingMedia="uploadingMedia"
          />
        </tooltip>
      </template>

      <tooltip
        data-cy="compose-tweetshots"
        v-if="isPostOfTypeLinkedIn"
        :content="tweet.mediaFile.length > 0 ? 'Cannot post tweetshots with images' : 'Tweetshots'"
        class="block"
      >
        <div>
          <new-button
            type="custom"
            @click="$emit('show-tweetshot-modal')"
            :disabled="tweet.mediaFile.length > 0"
          >
            <inline-svg class="h-5 w-5 text-main-color-100" src="/img/icons/tweetshot.svg" />
          </new-button>
        </div>
      </tooltip>
      <emoji-picker @selected-emoji="addEmoji($event)" />
      <new-drop-down icon buttonType="bordered" buttonSize="sm" data-cy="composer-li-dropdown">
        <template v-slot:button="slotProps">
          <inline-svg
            src="/img/icons/arrow-down-icon.svg"
            class="h-2 w-2 stroke-current"
            :class="['transform', slotProps.isOpen ? 'rotate-180' : 'rotate-0']"
          />
        </template>
        <template>
          <div class="flex flex-col gap-y-3">
            <div
              class="flex items-center justify-between gap-x-3 px-3 text-sm"
              :class="{
                'text-gray-80': !isPostPublic,
                'dropdown-main-text': isPostPublic,
              }"
            >
              <div class="flex items-center gap-x-3">
                <inline-svg src="/img/icons/eye.svg" class="h-5 w-5 stroke-current" />
                Post publicly
              </div>
              <base-toggle data-cy="public-post-toggle" v-model="isPostPublic" />
            </div>
            <div
              v-if="!isPostOfTypeLinkedIn && postType === 'carousel'"
              class="flex items-center justify-between gap-x-3 px-3 text-sm"
              :class="{
                'text-gray-80': !isThreadFinisherEnabled,
                'dropdown-main-text': isThreadFinisherEnabled,
              }"
            >
              <div class="flex items-center gap-x-3">
                <inline-svg class="h-5 w-5" src="/img/icons/new/custom-slide.svg" />
                Add custom last carousel slide
              </div>
              <base-toggle data-cy="thread-finisher-toggle" v-model="isThreadFinisherEnabled" />
            </div>
            <div
              v-if="!isPostOfTypeLinkedIn && postType === 'carousel'"
              class="flex flex-col gap-y-1 px-3"
            >
              <div
                class="flex items-center justify-between gap-x-3 text-sm"
                :class="{
                  'text-gray-80': !isCustomFileNameEnabled,
                  'dropdown-main-text': isCustomFileNameEnabled,
                }"
              >
                <div class="flex items-center gap-x-3">
                  <inline-svg class="h-5 w-5" src="/img/icons/new/edit.svg" />
                  Add custom file name
                </div>
                <base-toggle data-cy="custom-name-toggle" v-model="isCustomFileNameEnabled" />
              </div>
              <input
                v-if="isCustomFileNameEnabled"
                type="text"
                class="rounded-lg px-4 py-2 text-sm font-semi-medium text-ds-text-primary"
                v-model="customCarouselFileName"
                data-cy="custom-name-input"
              />
            </div>
          </div>
        </template>
      </new-drop-down>
    </portal>
  </div>
</template>

<script>
  import lodash from 'lodash';
  import { mapGetters } from 'vuex';
  import EnableNotificationsMixin from '@/views/Mixins/EnableNotificationsMixin.vue';
  import LinkedInMixin from '@/views/Mixins/LinkedInMixin.vue';
  import EmojiPicker from '@/components/Threads/EmojiPicker.vue';
  import UploadContainer from '@/components/UploadContainer.vue';
  import UploadContainerMixin from '@/views/Mixins/UploadContainerMixin.vue';
  import BreakpointsMixin from '@/views/Mixins/BreakpointsMixin.vue';
  import controller from '@/controller';
  import VueSlickCarousel from 'vue-slick-carousel';
  const config = require('@/config');

  export default {
    components: {
      UploadContainer,
      EmojiPicker,
      VueSlickCarousel,
    },
    props: {
      isMobileKeyboardVisible: {
        type: Boolean,
        default: false,
      },
      tweetshotContent: {
        type: Array,
        default: () => [],
      },
      tweet: {
        type: Object,
        default: null,
      },
      tweets: {
        type: Array,
        default: () => [],
      },
      isPostOfTypeLinkedIn: {
        type: Boolean,
        default: false,
      },
      tweetsCombinedStatuses: {
        type: String,
        default: '',
      },
      isVisible: {
        type: Boolean,
        default: false,
      },
      originalThread: {
        type: Object,
        default: null,
      },
      uploadingMedia: Array,
    },
    data() {
      return {
        linkedInText: '',
        didUserEditLinkedInText: false,
        isPostPublic: true,
        visibilityOptions: ['private', 'public'],
        postsTypes: ['text', 'carousel'],
        postType: 'text',
        isThreadFinisherEnabled: false,
        threadFinisherText: '',
        previousHeight: 0,
        isLoadingTweetshot: false,
        tweetshotUrl: '/img/tweetshot_placeholder_blank.png',
        currentTweetshotText: '',
        currentTweetshotMedia: null,
        slideHeight: 0,
        isCustomFileNameEnabled: false,
        customCarouselFileName: 'Shared from Hypefury',
      };
    },
    computed: {
      charactersLeftClass() {
        if (this.linkedInText.length >= 3000) return 'character-count-exceeded';
        if (this.linkedInText.length > 3000 - 100) return 'character-count-warning';
        return 'bg-light';
      },
      charactersLeftCounter() {
        return 3000 - this.linkedInText.length;
      },
      threadFinisherCharactersLeftClass() {
        if (this.threadFinisherText.length >= 280) return 'character-count-exceeded';
        if (this.threadFinisherText.length > 280 - 20) return 'character-count-warning';
        return 'bg-light';
      },
      threadFinisherCharactersLeftCounter() {
        return this.threadFinisherText.length;
      },
      canAddMoreVideos() {
        return (
          this.tweetshotContent.length === 0 &&
          (this.tweet.mediaFile.length === 0 ||
            this.tweet.mediaFile.filter((media) => media.type.includes('video')).length === 0)
        );
      },
      canAddMedia() {
        return (
          this.tweetshotContent.length === 0 &&
          (this.tweet.mediaFile.length === 0 ||
            (this.tweet.mediaFile.length < 9 &&
              !this.tweet.mediaFile[0].type.includes('video') &&
              !this.tweet.mediaFile[0].type.includes('pdf')))
        );
      },
      uploadContainerTooltipContent() {
        if (this.tweetshotContent.length > 0) return 'Cannot post media with tweetshots';
        if (!this.canAddMoreVideos) return 'Cannot add more than 1 video';
        if (!this.canAddMedia) return 'Cannot add more than 9 images';
        return 'Upload media';
      },
      ...mapGetters({ userProfile: 'getUserProfile' }),
      shouldDisplayTweetshotPreview() {
        return (
          (!window.Cypress && !this.isPostOfTypeLinkedIn && this.tweet.status.trim().length > 0) ||
          this.tweetshotContent.length > 0
        );
      },
    },
    mounted() {
      this.$emit('resetLinkedIn', () => {
        this.didUserEditLinkedInText = false;
        this.isThreadFinisherEnabled = false;
        this.threadFinisherText = '';
      });
      this.resize();
    },
    updated() {
      this.updateParentValues();
      this.resize();
    },
    methods: {
      capitalize: lodash.capitalize,
      clearMediaSelectionAtIndex(tweet, index) {
        this.$emit('cleared-media', tweet, index);
      },
      addEmoji(emoji) {
        const captionRef = this.$refs.linkedInText;
        const position = captionRef.selectionStart;
        this.linkedInText = [
          this.linkedInText.slice(0, position),
          emoji.native,
          this.linkedInText.slice(position),
        ].join('');
        const positionAfterTweet = position + 2;

        this.$nextTick(() => {
          captionRef.focus();
          captionRef.setSelectionRange(positionAfterTweet, positionAfterTweet);
        });
      },
      updateParentValues() {
        this.$emit('values-set', {
          linkedInText: this.linkedInText,
          isLinkedInPostPublic: this.isPostPublic,
          selectedLinkedInIds: this.selectedLinkedInIds,
          linkedInPostType: this.postType,
          isLinkedInThreadFinisherEnabled: this.isThreadFinisherEnabled,
          linkedInThreadFinisherText: this.threadFinisherText,
          didUserEditLinkedInText: this.didUserEditLinkedInText,
          customCarouselFileName: this.customCarouselFileName,
        });
      },
      updateValuesFromThread(thread) {
        if (thread.linkedIn) {
          this.linkedInText = this.isPostOfTypeLinkedIn
            ? thread.tweets[0].status
            : lodash.get(thread, 'linkedIn.text', '');
          this.didUserEditLinkedInText = lodash.get(
            thread,
            'linkedIn.didUserEditLinkedInText',
            true,
          );
          this.isPostPublic = lodash.get(thread, 'linkedIn.isPostPublic', true);
          this.postType = lodash.get(thread, 'linkedIn.postType', 'text');
          this.isThreadFinisherEnabled = Boolean(
            lodash.get(thread, 'linkedIn.threadFinisherText', null)
          );
          this.threadFinisherText = lodash.get(thread, 'linkedIn.threadFinisherText', '');

          const idsToPostAs = lodash.get(thread, 'linkedIn.idsToPostAs');
          if (idsToPostAs && idsToPostAs.length > 0) {
            this.selectedLinkedInIds = idsToPostAs;
          }
        }
      },
      resize() {
        const { linkedInText } = this.$refs;
        if (linkedInText && linkedInText.scrollHeight > 100) {
          linkedInText.style.height = linkedInText.scrollHeight - 4 + 'px';
        }
      },
      onFocus() {
        this.$emit('focused');
        this.resize();
      },
      async generateTweetshotPreview(tweetStatus, mediaURL) {
        this.isLoadingTweetshot = true;
        try {
          this.tweetshotUrl = await controller.generateTweetshotFromText(
            this.currentUser,
            this.userProfile.uid,
            tweetStatus,
            mediaURL,
          );
        } catch (error) {
          this.swalModal({
            title: 'Error',
            text: `An error occurred while creating the preview of your tweet.`,
            type: 'error',
          });
        }
        this.currentTweetshotText = tweetStatus;
        this.currentTweetshotMedia = mediaURL;
        this.isLoadingTweetshot = false;
        setTimeout(() => {
          this.resizeCarouselSlides();
        }, 200);
      },
      resizeCarouselSlides() {
        const firstSlideRef = this.$refs.firstSlide;
        if (firstSlideRef) {
          this.slideHeight = firstSlideRef.offsetHeight;
        }
      },
    },
    watch: {
      tweetsCombinedStatuses(text) {
        if (!this.didUserEditLinkedInText) {
          this.linkedInText = text;
        }
      },
      isVisible(visible) {
        if (visible) {
          if (!this.didUserEditLinkedInText) {
            this.linkedInText = this.tweetsCombinedStatuses.trim();
          }
          if (this.postType === 'carousel') {
            const isMediaUpdated =
              (
                this.tweet.media[0] &&
                config.buildStorageMediaURL(this.tweet.media[0].name) !== this.currentTweetshotMedia
              ) ||
              (this.currentTweetshotMedia && !this.tweet.media[0]);
            if (
              this.shouldDisplayTweetshotPreview &&
              (this.currentTweetshotText !== this.tweet.status)
              || isMediaUpdated
            ) {
              const mediaURL = this.tweet.media[0]
                ? config.buildStorageMediaURL(this.tweet.media[0].name)
                : null;
              this.generateTweetshotPreview(this.tweet.status, mediaURL);
            }
            if (this.isThreadFinisherEnabled) {
              this.$nextTick(() => {
                if (this.$refs.threadFinisherTextarea) {
                  this.$refs.threadFinisherTextarea.focus();
                }
                this.resize();
              });
            }
          } else if (this.postType === 'text') {
            this.$nextTick(() => {
              if (this.$refs.linkedInText && !this.isTabletOrMobile) {
                this.$refs.linkedInText.focus();
              }
              this.resize();
            });
          }
        }
      },
      isThreadFinisherEnabled(isEnabled) {
        if (isEnabled) {
          this.resizeCarouselSlides();
          this.$nextTick(() => {
            setTimeout(() => {
              this.$refs.carousel.next();
            }, 100);
          });
        }
      },
      originalThread(thread) {
        if (thread) {
          this.updateValuesFromThread(thread);
        }
      },
      postType() {
        if (!this.didUserEditLinkedInText) {
          this.linkedInText = '';
        }
        if (this.postType === 'carousel' && !this.isPostOfTypeLinkedIn) {
          this.generateTweetshotPreview(this.tweet.status);
        }

        this.$nextTick(() => {
          if (this.$refs.linkedInText && !this.isTabletOrMobile) {
            this.$refs.linkedInText.focus();
          }
        });
      },
      tweetshotContent() {
        if (
          this.tweetshotContent.length &&
          this.currentTweetshotText !== this.tweetshotContent[0].tweetText
        ) {
          this.generateTweetshotPreview(this.tweetshotContent[0].tweetText);
        }
      },
    },
    mixins: [LinkedInMixin, EnableNotificationsMixin, UploadContainerMixin, BreakpointsMixin],
  };
</script>

<style scoped>
  input[type='checkbox'] + label {
    display: block;
    margin: 0.2rem;
    cursor: pointer;
    padding: 0.2rem;
  }

  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox'] + label:before {
    content: '\2714';
    border: 0.1em solid #aaa;
    border-radius: 0.4rem;
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    padding-left: 0.2rem;
    padding-bottom: 0.3erm;
    margin-right: 0.5rem;
    vertical-align: bottom;
    color: transparent;
    transition: 0.2s;
  }

  input[type='checkbox'] + label:active:before {
    transform: scale(0);
  }

  input[type='checkbox']:checked + label:before {
    @apply border-gray-20 bg-main-color-30 text-white;
  }
</style>
