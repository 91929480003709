<script>
  import { isNil } from 'lodash';
  import storage from '@/storage';
  import SwalModalMixin from './SwalModalMixin.vue';
  import { getImageInformation } from '@/util/getImageInformation';
  import { EventBus } from '@/event-bus';
  const config = require('@/config');

  export default {
    data() {
      return {
        mediaStartIndex: 0,
      };
    },
    methods: {
      async filesChange(tweet, files, event) {
        if (!files) {
          if (event) this.resetFile(event);
          return;
        }

        if (
          tweet.mediaFile[0] &&
          (tweet.mediaFile[0].type.includes('gif') || tweet.mediaFile[0].type.includes('video')) &&
          !['instagram', 'threads'].includes(this.postType)
        ) {
          let message = 'Please choose either 1 GIF, 1 video or up to 4 images.';
          if (this.postType === 'linkedin') {
            message = 'Please choose either 1 video or up to 9 images.';
          } else if (this.postType === 'facebook') {
            message = 'You cannot cross-post both videos and images on Facebook.';
          }

          this.swalModal({
            title: 'Warning',
            text: message,
            type: 'warning',
          });
          if (event) this.resetFile(event);
          return;
        }

        if (this.mediaStartIndex + files.length > 10 && this.postType === 'threads') {
          this.swalModal({
            title: 'Warning',
            text: 'You cannot post more than 10 media.',
            type: 'warning',
          });
          if (event) this.resetFile(event);
          return;
        }

        if (
          this.mediaStartIndex + files.length > 4 &&
          !['linkedin', 'instagram', 'facebook', 'threads', 'tiktok'].includes(this.postType)
        ) {
          this.swalModal({
            title: 'Warning',
            text: `You cannot tweet more than 4 images.`,
            type: 'warning',
          });
          if (event) this.resetFile(event);
          return;
        }

        if (this.mediaStartIndex + files.length > 9 && this.postType === 'linkedin') {
          this.swalModal({
            title: 'Warning',
            text: 'You cannot post more than 9 images.',
            type: 'warning',
          });
          if (event) this.resetFile(event);
          return;
        }

        if (this.mediaStartIndex + files.length > 10 && this.postType === 'instagram') {
          this.swalModal({
            title: 'Warning',
            text: 'You cannot post more than 10 images.',
            type: 'warning',
          });
          if (event) this.resetFile(event);
          return;
        }

        if (this.mediaStartIndex + files.length > 35 && this.postType === 'tiktok') {
          this.swalModal({
            title: 'Warning',
            text: 'You cannot post more than 35 images.',
            type: 'warning',
          });
          if (event) this.resetFile(event);
          return;
        }

        if (this.imageCountInThread + files.length > 9 && this.showLinkedInBlock) {
          this.swalModal({
            title: 'Warning',
            text: "LinkedIn doesn't allow posting more than 9 images in a post.",
            type: 'warning',
          });
          if (event) this.resetFile(event);
          return;
        }

        let hasError = false;

        await Promise.all(
          Array.from(files).map(async (file) => {
            const fileType = file.type.toLowerCase();

            if (
              (this.isShareOnThreadsEnabled || this.postType === 'threads') &&
              tweet.count >= 1 &&
              tweet.mediaFile.length + files.length > 1
            ) {
              this.swalModal({
                title: 'Warning',
                text: "Threads doesn't allow posting more than 1 media for secondary posts.",
                type: 'warning',
              });
              if (event) this.resetFile(event);
              hasError = true;
            }

            if (
              this.showFacebookBlock &&
              this.imageCountInThread >= 1 &&
              fileType.includes('video')
            ) {
              this.swalModal({
                title: 'Warning',
                text: 'You cannot cross-post both videos and images on Facebook.',
                type: 'warning',
              });
              if (event) this.resetFile(event);
              hasError = true;
            }

            if (
              !['linkedin', 'instagram', 'facebook', 'tiktok'].includes(this.postType) &&
              fileType.includes('image') &&
              !['image/gif', 'image/png', 'image/jpeg'].includes(fileType)
            ) {
              this.swalModal({
                title: 'Warning',
                text: 'Only JPEG, PNG and GIF image formats are supported by Twitter.',
                type: 'warning',
              });
              if (event) this.resetFile(event);
              hasError = true;
            }

            if (
              this.showFacebookBlock &&
              this.imageCountInThread >= 1 &&
              fileType.includes('gif')
            ) {
              this.swalModal({
                title: 'Warning',
                text: 'You cannot cross-post both GIFs and images on Facebook.',
                type: 'warning',
              });
              if (event) this.resetFile(event);
              hasError = true;
            }

            if (
              !fileType.includes('video') &&
              !fileType.includes('image') &&
              this.postType !== 'linkedin'
            ) {
              this.swalModal({
                title: 'Warning',
                text: 'Unsupported file type(s).',
                type: 'warning',
              });
              if (event) this.resetFile(event);
              hasError = true;
            }

            if (
              (files.length > 1 || tweet.mediaFile.length > 0) &&
              (fileType.includes('video') || fileType.includes('gif')) &&
              !['instagram', 'threads', 'tiktok'].includes(this.postType)
            ) {
              let message = 'Please choose either 1 GIF, 1 video or up to 4 images.';
              if (this.postType === 'linkedin') {
                message = 'Please choose either 1 video or up to 9 images.';
              } else if (this.postType === 'facebook') {
                message = 'You cannot cross-post both videos and images on Facebook.';
              }

              this.swalModal({
                title: 'Warning',
                text: message,
                type: 'warning',
              });

              if (event) this.resetFile(event);

              hasError = true;
            }

            if (fileType.includes('video') && !this.canAddMoreVideos) {
              this.swalModal({
                title: 'Warning',
                text: 'Cannot add more than 10 videos in a thread.',
                type: 'warning',
              });
              if (event) this.resetFile(event);
              hasError = true;
            }

            // Cypress fails to properly execute getVideoInformation() function, so disabling this check for tests
            if (!window.Cypress) {
              if (
                fileType.includes('video') &&
                !['linkedin', 'instagram', 'facebook', 'tiktok'].includes(this.postType)
              ) {
                const { videoHeight, videoWidth } = await this.getVideoInformation(file);

                const TwitterMaximumAspectRatio = 3;
                const TwitterMinimumAspectRatio = 0.33;
                let videoAspectRatio = 0;
                if (videoHeight !== 0) {
                  videoAspectRatio = videoWidth / videoHeight;
                }

                if (videoHeight === 0 || videoWidth === 0) {
                  this.swalModal({
                    title: 'Warning',
                    text: 'Video dimensions should be greater than 0x0 pixels.',
                    type: 'warning',
                  });
                  if (event) this.resetFile(event);
                  hasError = true;
                }

                if (
                  videoAspectRatio < TwitterMinimumAspectRatio ||
                  videoAspectRatio > TwitterMaximumAspectRatio
                ) {
                  this.swalModal({
                    title: 'Warning',
                    text: 'Video aspect ratio should be between 1:3 and 3:1 (as per Twitter limits).',
                    type: 'warning',
                  });
                  if (event) this.resetFile(event);
                  hasError = true;
                }
              }
            }

            if (fileType.includes('video') && file.size > 512 * 1024 * 1024) {
              this.swalModal({
                title: 'Warning',
                text: 'Videos should be less than 512MB (as per Twitter limits).',
                type: 'warning',
              });
              if (event) this.resetFile(event);
              hasError = true;
            }

            if (fileType.includes('gif') && file.size > 15 * 1024 * 1024) {
              this.swalModal({
                title: 'Warning',
                text: 'GIFs should be less than 15MB (as per Twitter limits).',
                type: 'warning',
              });
              if (event) this.resetFile(event);
              hasError = true;
            }

            if (
              fileType.includes('image') &&
              !['linkedin', 'instagram', 'tiktok'].includes(this.postType)
            ) {
              const { imageHeight, imageWidth } = await getImageInformation(file);
              const MINIMUM_IMAGE_PIXELS = 4;
              const MAXIMUM_IMAGE_PIXELS = 8192;

              if (
                imageHeight < MINIMUM_IMAGE_PIXELS ||
                imageWidth < MINIMUM_IMAGE_PIXELS ||
                imageHeight > MAXIMUM_IMAGE_PIXELS ||
                imageWidth > MAXIMUM_IMAGE_PIXELS
              ) {
                await this.swalModal({
                  title: 'Warning',
                  text: 'Image dimensions should be between 4x4 and 8192x8192 pixels.',
                  type: 'warning',
                });
                if (event) this.resetFile(event);
                hasError = true;
              }
            }

            if (
              fileType.includes('image') &&
              !fileType.includes('gif') &&
              file.size > 20 * 1024 * 1024
            ) {
              this.swalModal({
                title: 'Warning',
                text: 'Images should be less than 20MB.',
                type: 'warning',
              });
              if (event) this.resetFile(event);
              hasError = true;
            } else if (
              fileType.includes('image') &&
              !fileType.includes('gif') &&
              file.size > 5 * 1024 * 1024
            ) {
              const { isConfirmed } = await this.swalModal({
                title: 'Downscale Image',
                text: `Your image is larger than 5MB (As per Twitter's limits). Do you want to downscale it?`,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
              });

              if (!isConfirmed) {
                if (event) this.resetFile(event);
              }
            }

            if (this.postType === 'linkedin' || this.showLinkedInBlock) {
              let message = '';
              if (
                fileType.includes('image') &&
                !['image/gif', 'image/png', 'image/jpeg'].includes(fileType)
              ) {
                message = 'Only JPEG, PNG and GIF image formats are supported by LinkedIn.';
                hasError = true;
              } else if (fileType.includes('video')) {
                // Cypress fails to properly execute getVideoInformation() function, so disabling this check for tests
                if (!window.Cypress) {
                  const { videoDuration } = await this.getVideoInformation(file);
                  if (videoDuration < 3 || videoDuration > 1800) {
                    message = 'Video should be between 3 seconds to 30 minutes long.';
                    hasError = true;
                  } else if (file.size < 75 * 1024 || file.size > 200 * 1024 * 1024 ) {
                    message = 'Videos file size should be between 75KB and 200MB.';
                    hasError = true;
                  } else if (fileType !== 'video/mp4' ) {
                    message = 'Only MP4 files are supported for videos.';
                    hasError = true;
                  }
                }
              }
              if (hasError) {
                this.swalModal({
                  title: 'Warning',
                  text: message,
                });
                if (event) this.resetFile(event);
              }
            }

            if (
              fileType.includes('image') &&
              !['linkedin', 'instagram', 'tiktok'].includes(this.postType)
            ) {
              const { imageHeight, imageWidth } = await getImageInformation(file);
              const MINIMUM_IMAGE_PIXELS = 4;
              const MAXIMUM_IMAGE_PIXELS = 8192;

              if (
                imageHeight < MINIMUM_IMAGE_PIXELS ||
                imageWidth < MINIMUM_IMAGE_PIXELS ||
                imageHeight > MAXIMUM_IMAGE_PIXELS ||
                imageWidth > MAXIMUM_IMAGE_PIXELS
              ) {
                this.swalModal({
                  title: 'Warning',
                  text: 'Image dimensions should be between 4x4 and 8192x8192 pixels.',
                  type: 'warning',
                });
                if (event) this.resetFile(event);
                hasError = true;
              }
            }

            if (this.postType === 'instagram') {
              const hasReel = await this.hasReel();
              if (fileType.includes('video')) {
                const { videoDuration, videoHeight, videoWidth } = await this.getVideoInformation(file);
                const videoAspectRatio = videoWidth / videoHeight;

                const instagramReelAspectRatio = 0.5625;
                const instagramMinimumAspectRatio = 0.8;
                // https://developers.facebook.com/docs/instagram-api/reference/ig-user/media#create-video-container
                // Normally the maximum allowed aspect ratio is 16/9 (1.77)
                // but i tested videos that have higher aspects ratios
                // and only videos above 1.9 failed
                const instagramMaximumAspectRatio = 1.9;
                let message = '';

                const isReel =
                  videoAspectRatio >= instagramReelAspectRatio &&
                  videoAspectRatio < instagramMinimumAspectRatio;


                if (isReel) {
                  if (videoHeight < 960 || videoWidth < 540) {
                    message = 'Reels must be at least 540 pixels wide and 960 pixels tall.';
                  }

                  if (videoDuration < 3 || videoDuration > 90) {
                    message = 'Reels must be between 3 and 90 seconds long.';
                  }
                }

                if ((hasReel || isReel) && this.tweet.fileList.length >= 1) {
                  message = 'One of the videos is a reel and you can only post one video as a reel.';
                }

                if (videoDuration < 3 || videoDuration > 60) {
                  message =
                    "As per Instagram's requirements the video must have a duration between 3 and 60 seconds.";
                } else if (videoWidth > 1920) {
                  message =
                    "As per Instagram's requirements the video's width shouldn't be more than 1920px.";
                } else if (
                  (videoAspectRatio < instagramMinimumAspectRatio ||
                    videoAspectRatio > instagramMaximumAspectRatio) &&
                  !isReel
                ) {
                  message =
                    "As per Instagram's requirements the video's aspect ratio must be between 4/5 and 16/9.";
                }

                if (file.size > 100 * 1024 * 1024) {
                  message = 'Videos should be less than 100MB (as per Instagram limits).';
                }

                if (message) {
                  this.swalModal({
                    title: 'Warning',
                    text: message,
                    type: 'warning',
                  });

                  this.clearMediaSelectionAtIndex(tweet, 0);
                  if (event) this.resetFile(event);
                  return;
                }
              } else {
                if (hasReel && files.length > 0) {
                  this.swalModal({
                    title: 'Warning',
                    text: 'One of the videos is a reel, cannot add media.',
                    type: 'warning',
                  });

                  this.clearMediaSelectionAtIndex(tweet, 0);
                  if (event) this.resetFile(event);
                  return;
                }
                const { imageHeight, imageWidth } = await getImageInformation(file);
                const instagramMinimumAspectRatio = 0.625;
                const instagramMaximumAspectRatio = 1.91;

                const imageAspectRatio = imageWidth / imageHeight;
                if (imageAspectRatio < instagramMinimumAspectRatio || imageAspectRatio > instagramMaximumAspectRatio) {
                  this.swalModal({
                    title: 'Error',
                    text: 'Image aspect ratio should be between 4:5 and 1.91:1.',
                  });
                  if (event) this.resetFile(event);
                  hasError = true;
                }
              }

              if (this.mediaStartIndex + files.length > 10) {
                this.swalModal({
                  title: 'Warning',
                  text: 'Cannot post more than 10 images.',
                  type: 'warning',
                });
                if (event) this.resetFile(event);
                return;
              }
            }

            if (this.postType === 'tiktok') {
              if (fileType.includes('video')) {
                if (files.length > 1) {
                  this.swalModal({
                    title: 'Warning',
                    text: 'You can only upload one video at a time.',
                    type: 'warning',
                  });
                  if (event) this.resetFile(event);
                  hasError = true;
                }

                const { videoDuration, videoHeight, videoWidth } = await this.getVideoInformation(
                  file,
                );
                const tiktokMinimumDuration = 3;
                const tiktokMaximumDuration = 600;
                const tiktokMaximumSize = 4 * 1024 * 1024 * 1024;
                let message = '';

                if (
                  videoDuration < tiktokMinimumDuration ||
                  videoDuration > tiktokMaximumDuration
                ) {
                  message = 'Video duration should be between 3 and 600 seconds.';
                } else if (file.size > tiktokMaximumSize) {
                  message = 'Videos should be less than 4GB.';
                } else if (
                  videoHeight < 360 ||
                  videoWidth < 360 ||
                  videoHeight > 4096 ||
                  videoWidth > 4096
                ) {
                  message = 'Video dimensions should be between 360x360 and 4096x4096 pixels.';
                } else if (
                  fileType !== 'video/mp4' &&
                  fileType !== 'video/webm' &&
                  fileType !== 'video/mov' &&
                  fileType !== 'video/quicktime'
                ) {
                  message = 'Only MP4, WebM and MOV files are supported for videos.';
                }

                if (message) {
                  this.swalModal({
                    title: 'Warning',
                    text: message,
                    type: 'warning',
                  });
                  if (event) this.resetFile(event);
                  hasError = true;
                }
              } else {
                const { imageHeight, imageWidth } = await getImageInformation(file);

                const tiktokMaximumSize = 20 * 1024 * 1024;
                const tiktokMaximumHeight = 1080;
                const tiktokMaximumWidth = 1080;
                let message = '';

                if (file.size > tiktokMaximumSize) {
                  message = 'Images should be less than 20MB.';
                } else if (imageHeight > tiktokMaximumHeight || imageWidth > tiktokMaximumWidth) {
                  message = 'Image dimensions should be less than 1080x1080 pixels.';
                } else if (!['image/webp', 'image/jpeg', 'image/jpg'].includes(fileType)) {
                  message = 'Only WebP and JPEG files are supported for images.';
                }

                if (message) {
                  this.swalModal({
                    title: 'Warning',
                    text: message,
                    type: 'warning',
                  });
                  if (event) this.resetFile(event);
                  hasError = true;
                }
              }
            }

            if (this.postType === 'linkedin') {
              if (
                !fileType.includes('video') &&
                !fileType.includes('image') &&
                !fileType.includes('pdf')
              ) {
                this.swalModal({
                  title: 'Warning',
                  text: 'Unsupported file type(s).',
                  type: 'warning',
                });
                if (event) this.resetFile(event);
                hasError = true;
              }

              if (tweet.mediaFile.length >= 1 && fileType.includes('pdf')) {
                this.swalModal({
                  title: 'Warning',
                  text: 'You cannot upload more than 1 PDF file.',
                  type: 'warning',
                });
                if (event) this.resetFile(event);
                hasError = true;
              }

              if (files.length > 1 && fileType.includes('pdf')) {
                this.swalModal({
                  title: 'Warning',
                  text: 'You cannot upload more than 1 PDF file.',
                  type: 'warning',
                });
                if (event) this.resetFile(event);
                hasError = true;
              }
            }
          }),
        );
        if (hasError) return;
        this.updateMediaFile(tweet, files);
        if (event) this.resetFile(event);
        this.$forceUpdate();
      },
      getVideoInformation(file) {
        const video = document.createElement('video');
        video.preload = 'metadata';
        video.src = URL.createObjectURL(file);
        return new Promise((resolve) => {
          video.onloadedmetadata = function () {
            window.URL.revokeObjectURL(video.src);
            resolve({
              videoDuration: video.duration,
              videoHeight: video.videoHeight,
              videoWidth: video.videoWidth,
            });
          };
        });
      },
      getMediaType(contentType) {
        const contentTypeLowerCase = contentType.toLowerCase();
        if (contentTypeLowerCase.includes('image')) {
          return contentTypeLowerCase.includes('gif') ? 'gif' : 'image';
        } else if (contentTypeLowerCase.includes('video')) {
          return 'video';
        } else if (contentTypeLowerCase.includes('pdf')) {
          return 'pdf';
        } else {
          return 'unknown';
        }
      },
      resetFile(event) {
        // This is so every file selection is considered a change, even if the file is the same one
        // Because we want the checks below to run of every file change
        if (event.type === 'change') {
          event.target.value = '';
        }
      },
      updateMediaFile(tweet, files) {
        const replacedMedia = tweet.media.slice(
          this.mediaStartIndex,
          this.mediaStartIndex + files.length
        );
        const replacedMediaNames = replacedMedia.map((media) => media.name);
        this.$emit('deleteReplacedMedia', replacedMediaNames);
        Array.from(files).map(async (f, i) => {
          tweet.mediaFile.splice(this.mediaStartIndex + i, 1, {
            url: URL.createObjectURL(f),
            type: this.getMediaType(f.type),
            mimeType: f.type,
          });
          tweet.fileList = isNil(tweet.fileList) ? [] : tweet.fileList;
          this.$set(tweet.fileList, this.mediaStartIndex + i, f);
          try {
            const loadingMediaIndex = this.mediaStartIndex + i;
            EventBus.$emit('set-uploading-media', loadingMediaIndex);

            const { file, thumbnail } = await storage.uploadNewMedia(f);

            const altText = tweet.mediaFile[i].altText || '';
            const name = file.metadata.name;
            const type = file.metadata.contentType;
            const size = file.metadata.size;

            tweet.mediaFile[loadingMediaIndex].url = config.buildStorageMediaURL(name);
            const m = {
              name,
              type,
              size,
              altText,
              ...(thumbnail ? { thumbnail } : {}),
            };
            this.$set(tweet.media, loadingMediaIndex, m);
            EventBus.$emit('unset-uploading-media', loadingMediaIndex);
          } catch(error) {
            this.swalModal({ title: 'Error', html: `Error uploading media ${f.name}` });
            console.error(error);
          };
        });
      },
      uploadSectionDragLeave(tweet, $event) {
        $event.preventDefault();
        if (!this.$refs['tweet-container']) {
          return;
        }
        this.$refs['tweet-container'].classList.remove('drop');
      },
      uploadSectionDragOver(tweet, $event) {
        $event.preventDefault();
        if (!this.$refs['tweet-container']) {
          return;
        }
        this.$refs['tweet-container'].classList.add('drop');
      },
      uploadSectionDrop(tweet, $event) {
        $event.preventDefault();
        const files = $event.dataTransfer.files;
        this.uploadSectionDragLeave(tweet, $event);
        this.mediaStartIndex = tweet.mediaFile.length;
        this.filesChange(tweet, files, $event);
      },
      triggerFileUpload: function (fromIndex) {
        if (!this.$refs.uploadBtn) return;
        this.$eventStore.composer('Action bar: Upload media');
        this.$refs.uploadBtn.click();
        this.mediaStartIndex = fromIndex;
      },
      async hasReel() {
        let hasReel = false;
        for (const mediaFile of this.tweet.fileList) {
          const fileType = mediaFile.type.toLowerCase();
          if (fileType.includes('video')) {
            const { videoHeight, videoWidth } = await this.getVideoInformation(mediaFile);
            const videoAspectRatio = videoWidth / videoHeight;
            const instagramReelAspectRatio = 0.5625;
            const instagramMinimumAspectRatio = 0.8;
            if (
              videoAspectRatio >= instagramReelAspectRatio &&
              videoAspectRatio < instagramMinimumAspectRatio
            ) {
              hasReel = true;
              break;
            }
          }
        }
        return hasReel;
      },
    },
    name: 'upload-container-mixin',
    mixins: [SwalModalMixin],
  };
</script>
